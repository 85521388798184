@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.case {
  padding: 20px 0 rem(66);
  color: var(--color-white);

  &__inner {
    display: grid;
    grid-template-columns: 1fr 580px;
    gap: 20px;
  }

  &__picture {
    img {
      overflow: hidden;
      border-radius: var(--radius-main);
    }
  }

  &__content {
    max-width: 480px;
  }

  h2 {
    margin-bottom: rem(63);
    letter-spacing: -0.03em;
  }

  h3 {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 1.2;
    color: inherit;
  }

  p {
    margin-bottom: 34px;
  }

  .btn {
    max-width: 160px;
  }

  @include media(tablet) {
    &__inner {
      justify-items: center;
      grid-template-columns: 1fr;
    }

    img {
      width: 100%;
      aspect-ratio: 16 / 6;
    }

    &__content,
    &__picture {
      width: 100%;
      max-width: none;
    }
  }
}