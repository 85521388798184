@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.reviews {
  padding: 2px 0 rem(83);

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(34);
  }

  &__item {
    h3 {
      margin-bottom: 20px;
      color: inherit;
    }

    p {
      margin-bottom: 15px;
    }
  }

  h2 {
    margin-bottom: rem(45);
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}