@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.solution {
  padding: 30px 0 rem(86);
  color: var(--color-white);

  .grid {
    margin-bottom: rem(68);

    p {
      color: var(--color-blue-400);
    }
  }

  .btn {
    margin: 0 auto;
    max-width: 250px;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 40px;
    letter-spacing: 0;

    +p {
      margin-bottom: rem(34);
      max-width: 700px;
    }
  }
}