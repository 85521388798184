@font-face {
  src: url("../opensanshebrew-bold.824f77d4.ttf") format("woff2");
  font-family: Open Sans Hebrew;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../opensanshebrew-regular.80438c1b.ttf") format("woff2");
  font-family: Open Sans Hebrew;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../SFProDisplay-Regular.bd6c9ce6.woff") format("woff");
  font-family: SFProDisplay;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../SFProDisplay-Medium.329eb5ac.woff") format("woff");
  font-family: SFProDisplay;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../SFProDisplay-Semibold.32f753de.woff") format("woff");
  font-family: SFProDisplay;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../SFProDisplay-Bold.a88449da.woff") format("woff");
  font-family: SFProDisplay;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

:root {
  --content-width: 1180px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "SFProDisplay", sans-serif;
  --font-family-secondary: "Open Sans Hebrew", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #000;
  --color-dark-400: #444;
  --color-dark-450: #3b3b3b;
  --color-dark-900: #292e3d;
  --color-dark-800: #1a2429;
  --color-blue-100: #b9c7d4;
  --color-blue-400: #354d64;
  --radius-main: 12px;
  --gradient: linear-gradient(89.88deg, #00000087 59.48%, #0000004e 78.99%, #0000 99.89%);
  --arrow: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.07745 0.744078C1.40289 0.418641 1.93053 0.418641 2.25596 0.744078L8.92263 7.41074C9.24807 7.73618 9.24807 8.26382 8.92263 8.58926L2.25596 15.2559C1.93053 15.5814 1.40289 15.5814 1.07745 15.2559C0.752015 14.9305 0.752015 14.4028 1.07745 14.0774L7.15486 8L1.07745 1.92259C0.752015 1.59715 0.752015 1.06951 1.07745 0.744078Z' fill='%23000'/%3E%3C/svg%3E%0A");
  --user: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00001 17.3335C7.74006 17.337 6.49603 17.0519 5.36335 16.5001C4.9488 16.2985 4.55162 16.0629 4.17585 15.7959L4.06168 15.7126C3.02819 14.9498 2.18324 13.9603 1.59168 12.8201C0.979792 11.64 0.662435 10.3294 0.666637 9.00008C0.666637 4.39771 4.39764 0.666748 9.00001 0.666748C13.6024 0.666748 17.3334 4.39771 17.3334 9.00008C17.3375 10.3287 17.0205 11.6387 16.4092 12.8184C15.8184 13.9579 14.9747 14.9471 13.9425 15.7101C13.5531 15.9951 13.14 16.246 12.7075 16.4601L12.6408 16.4934C11.5074 17.0482 10.2619 17.3355 9.00001 17.3335ZM9.00001 13.1667C7.75124 13.1643 6.6064 13.8618 6.03584 14.9726C7.90367 15.8977 10.0963 15.8977 11.9642 14.9726V14.9684C11.3929 13.8588 10.248 13.1629 9.00001 13.1667ZM9.00001 11.5001C10.8051 11.5024 12.4695 12.4752 13.3575 14.0468L13.37 14.0359L13.3817 14.0259L13.3675 14.0384L13.3592 14.0451C15.4667 12.2243 16.2203 9.28536 15.2489 6.67517C14.2775 4.06498 11.7859 2.33368 9.00085 2.33368C6.21577 2.33368 3.72417 4.06498 2.7528 6.67517C1.78144 9.28536 2.53501 12.2243 4.64251 14.0451C5.53106 12.4743 7.19531 11.5023 9.00001 11.5001ZM9.00001 10.6667C7.15906 10.6667 5.66668 9.17437 5.66668 7.33342C5.66668 5.49247 7.15906 4.00008 9.00001 4.00008C10.841 4.00008 12.3333 5.49247 12.3333 7.33342C12.3333 8.21747 11.9822 9.06532 11.357 9.69044C10.7319 10.3156 9.88407 10.6667 9.00001 10.6667ZM9.00001 5.66675C8.07954 5.66675 7.33335 6.41294 7.33335 7.33342C7.33335 8.25389 8.07954 9.00008 9.00001 9.00008C9.92049 9.00008 10.6667 8.25389 10.6667 7.33342C10.6667 6.41294 9.92049 5.66675 9.00001 5.66675Z' fill='%23000'/%3E%3C/svg%3E%0A");
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-dark-400);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.4;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.background {
  background-color: var(--color-blue-400);
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background: var(--gradient);
  position: absolute;
  inset: 0;
}

.background {
  background-color: var(--color-blue-100);
}

.background--dark {
  background-color: var(--color-blue-400);
}

.logo {
  width: 100%;
  max-width: 120px;
  margin-right: 20px;
}

.logo img {
  width: 100%;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-700);
  color: var(--color-dark-400);
  line-height: 1.05;
  overflow: hidden;
}

h1 {
  font-size: clamp(2.25rem, 1.9951rem + 1.0458vi, 56px);
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 34px;
  }
}

h2, .title {
  letter-spacing: -.015em;
  text-align: left;
  color: inherit;
  margin-bottom: 2rem;
  font-size: clamp(2rem, 1.7143rem + 1.4286vi, 3rem);
}

h3, .title--sec {
  font-size: 32px;
}

p {
  font-size: 20px;
  line-height: 1.4;
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);
  margin: 0 auto;
  padding: 24px 0;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header button.formsapp-popup-button, .header .btn {
  color: var(--color-white) !important;
  padding: 18px 23px !important;
}

.header button.formsapp-popup-button:after, .header .btn:after {
  filter: invert();
}

@media only screen and (max-width: 1180px) {
  .header button.formsapp-popup-button, .header .btn {
    padding: 18px 10px !important;
  }
}

.header .btn--user {
  color: var(--color-blue-400) !important;
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin-left: 36px;
  margin-right: auto;
  display: flex;
}

@media only screen and (max-width: 1280px) {
  .header .nav__list {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: 13px;
}

@media only screen and (max-width: 1280px) {
  .nav__item:not(:last-of-type) {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .header .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  letter-spacing: .035em;
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--color-dark-450);
  background-color: #0000;
  padding: 10px;
  font-size: 16px;
}

.nav__link.true {
  border-bottom: 1px solid var(--color-dark-400);
}

.nav__btns {
  display: flex;
}

.nav__btns .request-btn {
  min-width: 251px;
}

@media only screen and (max-width: 1180px) {
  .nav__btns .request-btn {
    min-width: 220px;
  }
}

.nav__btns .btn--user {
  margin-right: 14px;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 475px) {
  .nav__btns {
    flex-direction: column;
  }
}

@media only screen and (max-width: 992px) {
  .nav .nav__list {
    margin: 0 auto;
  }

  .nav .nav__item {
    margin-right: 0;
  }

  .nav--footer {
    flex-direction: column;
  }

  .nav--footer .logo, .nav--footer .nav__list {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) and (max-width: 576px) {
  .nav--footer .nav__list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 992px) {
  .nav--footer .nav__inner {
    flex-direction: column;
    align-items: center;
  }
}

[class].btn {
  border-radius: calc(var(--radius-main)  - 4px);
  width: 100%;
  font-weight: var(--fw-500);
  text-align: center;
  color: var(--color-dark-450);
  background-color: var(--color-white);
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  font-size: 16px;
  line-height: 1;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
}

[class].btn:hover, [class].btn:focus, [class].btn--reverse {
  border-color: var(--color-blue-400);
  color: var(--color-white);
  background-color: var(--color-blue-400);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  border-color: var(--color-blue-400);
  background-color: var(--color-white);
  color: var(--color-blue-400) !important;
}

[class].btn--user {
  min-width: 115px;
  position: relative;
}

[class].btn--user:before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: var(--user);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
  translate: 0 -50%;
}

[class].btn--user:hover:before, [class].btn--user:focus:before {
  filter: invert();
}

@media only screen and (max-width: 475px) {
  [class].btn {
    width: max-content;
    min-width: auto;
    margin: 0 auto;
    padding-left: 40px !important;
  }
}

button.formsapp-popup-button {
  margin: 0 !important;
  padding: 18px 23px !important;
}

[class].request-btn {
  font-weight: var(--fw-600);
  justify-content: space-between;
  font-size: 15px;
  display: flex;
  position: relative;
  color: var(--color-blue-400) !important;
}

[class].request-btn:after {
  content: "";
  width: 10px;
  height: 18px;
  background-image: var(--arrow);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  transition-property: filter;
  transition-duration: .2s;
  position: relative;
  right: 0;
}

[class].request-btn:hover:after, [class].request-btn:focus:after {
  filter: invert(0);
}

.arrow.btn {
  justify-content: space-between;
  padding: 17px 24px;
  display: flex;
  position: relative;
}

.arrow.btn:after {
  content: "";
  width: 10px;
  height: 18px;
  background-image: var(--arrow);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  transition-property: filter;
  transition-duration: .2s;
  position: relative;
  right: 0;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-black);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  color: var(--color-dark-900);
  padding: 4.875rem 0;
}

.policy > * {
  font-family: var(--font-family-primary);
}

.policy__inner {
  max-width: 680px;
}

.policy__content {
  margin-bottom: 3rem;
}

.policy h1 {
  margin-bottom: 2.5rem;
  position: relative;
}

.policy h2 {
  color: var(--color-blue-400);
  margin-bottom: 24px;
  font-size: 30px;
}

.policy ul {
  margin-bottom: 24px;
  padding-left: 15px;
}

.policy li {
  padding-left: 5px;
  font-size: 20px;
  line-height: 1.43;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
  margin-right: 5px;
}

.policy p {
  font-size: 20px;
  line-height: 1.2;
}

.policy p:not(:last-child) {
  margin-bottom: 30px;
}

.policy a {
  color: var(--color-blue-400);
  text-decoration: underline;
}

.policy b, .policy strong {
  font-weight: var(--fw-600);
}

@media only screen and (max-width: 768px) {
  .policy {
    padding: 40px 0;
  }

  .policy h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.footer {
  color: var(--color-white);
  background-color: var(--color-blue-400);
  padding: 3.75rem 0;
}

.footer .logo {
  max-width: 126px;
  margin: 0 auto 24px;
  display: block;
}

.footer .logo img {
  width: -moz-fit-content;
  width: fit-content;
  filter: invert();
  margin: 0 auto;
}

.footer__inner {
  justify-content: space-between;
  display: flex;
}

.footer .nav {
  margin-bottom: 47px;
}

.footer .nav__inner {
  justify-content: center;
}

.footer .nav__list {
  margin: 0;
}

@media only screen and (max-width: 992px) {
  .footer .nav__list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.footer .nav__item:not(:last-child) {
  margin-right: 3.125rem;
}

@media only screen and (max-width: 1180px) {
  .footer .nav__item:not(:last-child) {
    margin-right: 20px;
  }
}

.footer .nav__link {
  color: var(--color-white);
}

.footer .copyright {
  text-align: center;
  font-size: 16px;
}

.footer button.formsapp-popup-button, .footer .btn {
  padding: 10px 16px !important;
}

.blog {
  padding: 4.375rem 0 5.25rem;
}

.blog__grid {
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-bottom: 1.75rem;
  display: grid;
}

.blog__inner {
  border-radius: var(--radius-main);
  background-position: 100%;
  background-repeat: no-repeat;
  margin-bottom: 2.1875rem;
  padding: 2.1875rem 4.1875rem 5.9375rem;
  position: relative;
  overflow: hidden;
}

.blog__inner:before {
  content: "";
  z-index: 1;
  -webkit-clip-path: polygon(0 0, 59% 0%, 52% 100%, 0% 100%);
  clip-path: polygon(0 0, 59% 0%, 52% 100%, 0% 100%);
  width: 100%;
  height: 100%;
  background-color: var(--color-blue-400);
  position: absolute;
  top: 0;
  left: 0;
}

.blog__content {
  z-index: 1;
  max-width: 500px;
  color: var(--color-white);
  padding: 2rem 0;
  position: relative;
}

.blog__content h2 {
  letter-spacing: 0;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.2;
}

.blog__content p {
  margin-bottom: 40px;
}

.blog h1 {
  text-transform: none;
  margin-bottom: 44px;
}

.blog h1 + p {
  margin-bottom: 3.125rem;
}

.blog .btn {
  max-width: 160px;
  border-color: #0000;
  font-size: 19px;
}

.blog .btn:hover, .blog .btn:focus {
  border-color: var(--color-white);
}

.blog .btn:hover:after, .blog .btn:focus:after {
  filter: invert();
}

@media only screen and (max-width: 768px) {
  .blog__grid {
    grid-template-columns: 1fr;
  }

  .blog__inner {
    padding-bottom: 50px;
    position: relative;
  }

  .blog__inner:after {
    content: "";
    background-color: var(--color-black);
    opacity: .7;
    position: absolute;
    inset: 0;
  }

  .blog h3 {
    font-size: 27px;
    line-height: 1.2;
  }

  .blog {
    padding-top: 20px;
  }

  .blog__inner {
    padding: 30px;
  }

  .blog .btn {
    max-width: 220px;
    width: 100%;
  }
}

.article {
  color: var(--color-white);
  padding: 4.25rem 0 5.1875rem;
}

.article__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
}

.article__content {
  border-radius: var(--radius-main);
  padding: 45px 40px 34px;
  overflow: hidden;
}

.article h1 {
  text-transform: none;
  margin-bottom: 40px;
  line-height: 1.2;
}

.article h2 {
  font-family: var(--font-family-primary);
  letter-spacing: .035em;
  margin-bottom: 22px;
  font-size: 30px;
}

.article img {
  border-radius: var(--radius-main);
  aspect-ratio: 16 / 6;
  margin-bottom: 44px;
  overflow: hidden;
}

.article p {
  max-width: 680px;
  font-family: var(--font-family-primary);
  margin-bottom: 44px;
}

@media only screen and (max-width: 992px) {
  .article {
    padding: 30px 0;
  }

  .article__content {
    padding: 40px 20px;
  }
}

.articles__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
}

@media only screen and (max-width: 768px) {
  .articles__grid {
    gap: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .articles__grid {
    grid-template-columns: 1fr;
    grid-auto-rows: 300px;
  }
}

.contact {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 5.125rem 0 5.25rem;
  display: flex;
}

.contact__wrapper {
  border-radius: var(--radius-main);
  width: 100%;
  max-width: 700px;
  background-color: var(--color-white);
  padding: 5.1875rem;
}

.contact__form {
  width: 100%;
}

.contact h2 {
  margin-bottom: 30px;
}

.contact label {
  margin-bottom: 16px;
  display: block;
}

.contact label:last-of-type {
  margin-bottom: 22px;
}

.contact input, .contact textarea {
  border-radius: var(--radius-main);
  width: 100%;
  color: var(--color-blue-400);
  background-color: #f7f7f7;
  border-color: #0000;
  padding: 13px;
}

.contact textarea {
  min-height: 240px;
  resize: none;
}

.contact .btn {
  max-width: none;
  padding-block: 15px;
  padding-inline: 15px !important;
}

@media only screen and (max-width: 768px) {
  .contact__wrapper {
    padding: 40px;
  }

  .contact .btn {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .contact h2 {
    text-align: center;
    margin: 0 auto 40px;
  }

  .contact__wrapper {
    padding: 40px 20px;
  }

  .contact__inner {
    flex-direction: column;
    align-items: center;
  }

  .contact__form {
    max-width: none;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

.grid {
  gap: 18px 20px;
  display: grid;
}

.grid__item {
  border-radius: var(--radius-main);
  background-color: var(--color-white);
  padding: 2.125rem;
  position: relative;
  overflow: hidden;
}

.grid h3 {
  margin-bottom: 15px;
  font-size: 32px;
}

.grid p {
  line-height: 1.4;
}

.grid[data-items="7"] {
  --column: 6;
  grid-template-columns: repeat(var(--column), 1fr);
}

.grid[data-items="7"] .grid__item:nth-last-child(-n+7) {
  grid-column: span calc(var(--column) / 3);
}

.grid[data-items="7"] .grid__item:nth-child(n+4) {
  grid-column: span calc(var(--column) / 2);
}

.grid[data-items="6"] {
  --column: 6;
  grid-template-columns: repeat(var(--column), 1fr);
}

.grid[data-items="6"] .grid__item:nth-child(1) {
  grid-column: span var(--column);
}

.grid[data-items="6"] .grid__item:nth-child(2), .grid[data-items="6"] .grid__item:nth-child(3) {
  grid-column: span calc(var(--column) / 2);
}

.grid[data-items="6"] .grid__item:nth-child(n+4) {
  grid-column: span calc(var(--column) / 3);
}

.grid[data-items="5"] {
  --column: 6;
  grid-template-columns: repeat(var(--column), 1fr);
}

.grid[data-items="5"] .grid__item:nth-last-child(-n+5) {
  grid-column: span calc(var(--column) / 3);
}

.grid[data-items="5"] .grid__item:nth-child(n+4) {
  grid-column: span calc(var(--column) / 2);
}

.grid[data-items="7"] {
  --column: 6;
  grid-template-columns: repeat(var(--column), 1fr);
}

.grid[data-items="7"] .grid__item:nth-child(1) {
  grid-column: span var(--column);
}

.grid[data-items="7"] .grid__item:nth-child(n+3) {
  grid-column: span calc(var(--column) / 3);
}

.grid[data-items="7"] .grid__item:nth-child(5) {
  grid-column: span var(--column);
}

.grid[data-items="8"] {
  --column: 6;
  grid-template-columns: repeat(var(--column), 1fr);
}

.grid[data-items="8"] .grid__item:nth-child(-n+4) {
  grid-column: span calc(var(--column) / 3);
}

.grid[data-items="8"] .grid__item:nth-child(n+4) {
  grid-column: span calc(var(--column) / 2);
}

.grid[data-items="4"] {
  --column: 4;
  grid-template-columns: repeat(calc(var(--column) / 2), 1fr);
}

.grid[data-items="3"] {
  --column: 3;
  grid-template-columns: repeat(var(--column), 1fr);
}

.grid[data-items="2"] {
  --column: 2;
  grid-template-columns: repeat(var(--column), 1fr);
}

.grid[data-items="2"] .grid__item:nth-child(3) {
  grid-column: span var(--column);
}

@media only screen and (max-width: 1180px) {
  .grid[data-items] {
    --column: 2;
  }

  .grid[data-items] .grid__item {
    grid-column: auto !important;
  }
}

@media only screen and (max-width: 768px) {
  .grid[data-items] {
    --column: 1;
  }
}

.news {
  border-radius: var(--radius-main);
  height: 100%;
  position: relative;
  overflow: hidden;
}

.news:before {
  content: "";
  background: linear-gradient(360deg, #0000009f 33.13%, #6666663b 61.36%);
  position: absolute;
  inset: 0;
}

.news h3 {
  color: var(--color-white);
  padding: 0 2.8125rem 0 40px;
  line-height: 1.5;
  position: absolute;
  bottom: 35px;
}

.news img {
  height: 100%;
}

.news a:before {
  content: "";
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 768px) {
  .news h3 {
    padding: 0 15px;
    font-size: 27px;
    line-height: 1.2;
  }
}

.hero {
  padding: 4.1875rem 0 5.1875rem;
}

.hero__inner {
  border-radius: var(--radius-main);
  min-height: 584px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 1;
  height: 100%;
  padding-inline: 4.25rem;
  position: relative;
}

.hero__content > * {
  color: var(--color-white);
}

.hero__content h1 {
  margin-bottom: 24px;
}

.hero__content p {
  letter-spacing: -.003em;
  margin-bottom: 56px;
  font-size: 25px;
}

.hero__content .btn {
  max-width: 251px;
}

.hero--about {
  padding-bottom: 4.125rem;
}

.hero--about .hero__content {
  max-width: 765px;
  padding-left: 0;
}

.hero--about h1 {
  margin-bottom: 17px;
}

.hero--about h2 {
  letter-spacing: 0;
  margin-bottom: 20px;
  font-size: 32px;
}

.hero--about p {
  letter-spacing: 0;
  font-size: 20px;
}

.hero--about p:not(:last-child) {
  margin-bottom: 2.3125rem;
}

.hero--about p:last-child {
  margin-bottom: 0;
}

.hero--service {
  padding-bottom: 3.125rem;
}

.hero--service p {
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .hero {
    min-height: 300px;
    padding-top: 0;
  }

  .hero__content {
    padding: 20px;
  }

  .hero__inner {
    min-height: 300px;
  }
}

.service {
  padding: 4.1875rem 0;
}

.service h2 {
  margin-bottom: 15px;
}

.service h2 + p {
  max-width: 650px;
  margin-bottom: 2.125rem;
  font-size: 20px;
  line-height: 1.4;
}

.service .btn {
  max-width: 160px;
  justify-content: space-between;
  margin: 0 auto;
  padding-inline: 22px;
  display: flex;
}

.service--home .grid {
  gap: 20px;
  margin-bottom: 2rem;
}

.service--home .grid__item:before {
  content: "";
  width: 86px;
  height: 86px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 22px;
  display: block;
  position: relative;
}

.service--home .grid__item:first-child {
  padding-top: 3.875rem;
  padding-bottom: 3.875rem;
}

.service--home .grid__item:first-child:before {
  margin-bottom: 31px;
}

.service--home .grid__item:first-child h3 {
  margin-bottom: 15px;
  font-size: 40px;
}

.service--home .grid__item:first-child p {
  line-height: 1.4;
}

.service--home .grid__item--collaboration:before {
  background-image: url("../images/icons/collaboration.svg");
}

.service--home .grid__item--analyze:before {
  background-image: url("../images/icons/analyze.svg");
}

.service--home .grid__item--statistics:before {
  background-image: url("../images/icons/statistics.svg");
}

.service--home .grid__item--doughnut:before {
  background-image: url("../images/icons/doughnut.svg");
}

.service--home .grid__item--speed:before {
  background-image: url("../images/icons/speed.svg");
}

.service--home .grid__item--error:before {
  background-image: url("../images/icons/error.svg");
}

.service--services {
  color: var(--color-white);
  padding: 20px 0;
}

.service--services:not(:last-child) {
  padding-bottom: 50px;
}

.service--services .grid__item {
  padding-right: 28px;
}

.service--services .grid h3 {
  line-height: 1.15;
}

.service--services .grid p {
  color: var(--color-dark-400);
}

.service--services h2 + p {
  max-width: 680px;
  margin-bottom: 4.375rem;
}

.service--services h2:not(:first-child) {
  letter-spacing: 0;
  margin-bottom: 24px;
  font-size: 40px;
}

.service--services h2:not(:first-child) + p {
  margin-bottom: 2rem;
}

.service--analitycs {
  padding-top: 37px;
}

.service--analitycs h2 {
  letter-spacing: 0;
  font-size: 40px;
}

.service--analitycs h2 + p {
  margin-bottom: 2.3125rem;
}

.choose {
  padding: 5.25rem 0;
}

.choose__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 2.0625rem;
  display: grid;
}

.choose__item {
  border-radius: var(--radius-main);
  color: var(--color-white);
  background-color: var(--color-blue-400);
  padding: 40px;
}

.choose__item h3 {
  color: inherit;
  margin-bottom: 15px;
}

.choose__item p {
  line-height: 1.4;
}

.choose h2 {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 768px) {
  .choose__grid {
    grid-template-columns: 1fr;
  }
}

.reviews {
  padding: 2px 0 5.1875rem;
}

.reviews__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 2.125rem;
  display: grid;
}

.reviews__item h3 {
  color: inherit;
  margin-bottom: 20px;
}

.reviews__item p {
  margin-bottom: 15px;
}

.reviews h2 {
  margin-bottom: 2.8125rem;
}

@media only screen and (max-width: 768px) {
  .reviews__grid {
    grid-template-columns: 1fr;
  }
}

.featured {
  color: var(--color-white);
  padding: 5.4375rem 0 5.1875rem;
}

.featured__inner {
  grid-template-columns: 585px 1fr;
  gap: 30px;
  display: grid;
}

.featured__inner:not(:last-child) {
  margin-bottom: 9.5625rem;
}

.featured__inner h3 {
  font-weight: var(--fw-600);
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 1.2;
}

.featured__inner--reverse {
  grid-template-columns: 1fr 585px;
}

.featured__inner--reverse .featured__content {
  grid-column: 2 / 3;
}

.featured__inner--reverse .featured__picture {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
}

.featured__content {
  width: 100%;
  max-width: 800px;
}

.featured__picture {
  justify-self: end;
}

.featured__picture img {
  border-radius: var(--radius-main);
  overflow: hidden;
}

.featured h2 {
  color: var(--color-white);
  margin-bottom: 3.0625rem;
}

.featured h3 {
  color: var(--color-white);
}

.featured h4 {
  letter-spacing: .055em;
  margin-bottom: 3px;
  font-size: 32px;
}

.featured p {
  color: var(--color-white);
}

.featured p:not(:last-child) {
  margin-bottom: 20px;
}

.featured--help {
  color: var(--color-blue-400);
  background-color: var(--color-white);
}

.featured--help .featured__inner {
  align-items: center;
}

.featured--help .featured__inner:not(:last-child) {
  margin-bottom: 6.5rem;
}

.featured--help h2, .featured--help h3, .featured--help p {
  color: inherit;
}

.featured--help h2 {
  margin-bottom: 24px;
}

.featured--help h3 {
  margin-bottom: 13px;
  font-size: 40px;
}

@media only screen and (max-width: 992px) {
  .featured__inner {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .featured__inner img {
    aspect-ratio: 16 / 7;
    width: 100%;
  }

  .featured__picture {
    max-width: 100%;
    width: 100%;
  }

  .featured__inner--reverse .featured__content {
    grid-area: 1 / auto / 2;
  }

  .featured__inner--reverse .featured__picture {
    grid-area: 2 / auto / 3;
  }

  .featured .featured__inner:not(:last-child) {
    margin-bottom: 2.5rem;
  }
}

.study {
  padding: 5.375rem 0;
}

.study__inner {
  border-radius: var(--radius-main);
  background-position: 100%;
  background-repeat: no-repeat;
  margin-bottom: 4.25rem;
  padding: 2.1875rem 4.1875rem;
  position: relative;
  overflow: hidden;
}

.study__inner:before {
  content: "";
  z-index: 1;
  -webkit-clip-path: polygon(0 0, 57% 0%, 52% 100%, 0% 100%);
  clip-path: polygon(0 0, 57% 0%, 52% 100%, 0% 100%);
  width: 100%;
  height: 100%;
  background-color: var(--color-blue-400);
  position: absolute;
  top: 0;
  left: 0;
}

.study__content {
  z-index: 1;
  max-width: 500px;
  color: var(--color-white);
  position: relative;
}

.study__btn.btn {
  margin: 0 auto;
  max-width: 160px !important;
}

.study__btn.btn:after {
  filter: invert();
}

.study .btn {
  max-width: 120px;
  justify-content: space-between;
  padding: 17px 24px;
}

.study h2 {
  margin-bottom: 2.3125rem;
}

.study h3 {
  color: var(--color-white);
  margin-bottom: 17px;
  font-size: 32px;
  line-height: 1.2;
}

.study h3 + p {
  margin-bottom: 40px;
}

.study img {
  border-radius: var(--radius-main);
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .study__inner {
    margin-bottom: 30px 20px;
    padding: 20px;
  }

  .study__inner:after {
    content: "";
    background-color: var(--color-black);
    opacity: .7;
    position: absolute;
    inset: 0;
  }

  .study__inner .btn {
    width: 100%;
    max-width: 200px;
  }
}

.values {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.values__inner {
  padding: 4.25rem 0;
}

.values .grid {
  margin-bottom: 2.125rem;
}

.values .grid h3 {
  margin-bottom: 10px;
}

.values .btn {
  max-width: 160px;
  margin: 0 auto;
}

.team {
  background-color: var(--color-white);
  padding: 4.125rem 0 34px;
  overflow: hidden;
}

.team__slide {
  border-radius: var(--radius-main);
  height: initial;
  font-family: var(--font-family-secondary);
  color: var(--color-white);
  background-color: var(--color-blue-400);
  flex-shrink: 0;
  padding: 2.125rem;
  overflow: hidden;
}

.team__slide h3 {
  font-weight: var(--fw-400);
  text-align: center;
  color: inherit;
  margin: 0 auto 16px;
}

.team__slide span {
  font-weight: var(--fw-700);
  text-align: center;
  margin: 0 auto 18px;
  font-size: 20px;
  display: block;
}

.team__slide p {
  font-weight: var(--fw-700);
  line-height: 1.6;
}

.culture {
  color: var(--color-white);
  padding: 4.125rem 0;
}

.culture h2 {
  margin-bottom: 18px;
}

.culture h2 + p {
  max-width: 660px;
  margin-bottom: 2rem;
}

.culture h3 {
  line-height: 1.2;
}

.culture .grid__item {
  padding-right: 40px;
}

.culture .grid p {
  color: var(--color-dark-400);
}

.culture--philosophy {
  padding: 0 0 4.875rem;
}

.solution {
  color: var(--color-white);
  padding: 30px 0 5.375rem;
}

.solution .grid {
  margin-bottom: 4.25rem;
}

.solution .grid p {
  color: var(--color-blue-400);
}

.solution .btn {
  max-width: 250px;
  margin: 0 auto;
}

.solution h2 {
  letter-spacing: 0;
  margin-bottom: 20px;
  font-size: 40px;
}

.solution h2 + p {
  max-width: 700px;
  margin-bottom: 2.125rem;
}

.work {
  background-color: var(--color-white);
  padding: 5.25rem 0;
}

.work h2 {
  margin-bottom: 14px;
}

.work h2 + p {
  max-width: 700px;
  margin-bottom: 2.125rem;
}

.work .grid__item {
  color: var(--color-white);
  background-color: var(--color-blue-400);
}

.work .grid h3 {
  color: inherit;
}

.case {
  color: var(--color-white);
  padding: 20px 0 4.125rem;
}

.case__inner {
  grid-template-columns: 1fr 580px;
  gap: 20px;
  display: grid;
}

.case__picture img {
  border-radius: var(--radius-main);
  overflow: hidden;
}

.case__content {
  max-width: 480px;
}

.case h2 {
  letter-spacing: -.03em;
  margin-bottom: 3.9375rem;
}

.case h3 {
  color: inherit;
  margin-bottom: 30px;
  font-size: 40px;
  line-height: 1.2;
}

.case p {
  margin-bottom: 34px;
}

.case .btn {
  max-width: 160px;
}

@media only screen and (max-width: 992px) {
  .case__inner {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .case img {
    width: 100%;
    aspect-ratio: 16 / 6;
  }

  .case__content, .case__picture {
    width: 100%;
    max-width: none;
  }
}

.case-desc .grid:not(:last-child) {
  margin-bottom: 2.3125rem;
}

.case-desc .grid__item {
  padding-right: 40px;
}

.case-desc .grid p {
  color: var(--color-blue-400);
  margin-bottom: 0;
}

.case-desc h3 {
  margin-bottom: 10px;
  line-height: 1.2;
}

.case-desc p:last-child {
  margin-bottom: 0;
}

/*# sourceMappingURL=main.css.map */
