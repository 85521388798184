@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.choose {
  padding: rem(84) 0;

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(33);
  }

  &__item {
    border-radius: var(--radius-main);
    padding: 40px;
    color: var(--color-white);
    background-color: var(--color-blue-400);

    h3 {
      margin-bottom: 15px;
      color: inherit;
    }

    p {
      line-height: 1.4;
    }
  }

  h2 {
    margin-bottom: rem(32);
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}