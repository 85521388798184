@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.blog {
  padding: rem(70) 0 rem(84);

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: rem(28);
    gap: 24px;
  }

  &__inner {
    position: relative;
    overflow: hidden;
    margin-bottom: rem(35);
    border-radius: var(--radius-main);
    padding: rem(35) rem(67) rem(95);
    background-position: right center;
    background-repeat: no-repeat;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      clip-path: polygon(0 0, 59% 0%, 52% 100%, 0% 100%);
      width: 100%;
      height: 100%;
      background-color: var(--color-blue-400);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: rem(32) 0;
    max-width: 500px;
    color: var(--color-white);

    h2 {
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 1.2;
      letter-spacing: 0;
    }

    p {
      margin-bottom: 40px;
    }
  }

  h1 {
    margin-bottom: 44px;
    text-transform: none;

    +p {
      margin-bottom: rem(50);
    }
  }

  .btn {
    border-color: transparent;
    max-width: 160px;
    font-size: 19px;

    &:hover,
    &:focus {
      border-color: var(--color-white);

      &::after {
        filter: invert(1);
      }
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__inner {
      position: relative;
      padding-bottom: 50px;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: var(--color-black);
        opacity: 0.7;
      }
    }

    h3 {
      font-size: 27px;
      line-height: 1.2;
    }
  }

  @include media(mobile-l) {
    padding-top: 20px;

    &__inner {
      padding: 30px;
    }

    .btn {
      max-width: 220px;
      width: 100%;
    }
  }
}