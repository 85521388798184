@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  margin: 0 auto;
  padding: 24px 0;
  width: 100%;
  background-color: var(--color-white);
  transition-property: transform, padding;
  transition-duration: 0.15s;

  button.formsapp-popup-button,
  .btn {
    padding: 18px 23px !important;
    color: var(--color-white) !important;

    &::after {
      filter: invert(1);
    }

    @include media(tablet-wide) {
      padding: 18px 10px !important;
    }
  }

  .btn--user {
    color: var(--color-blue-400) !important;
  }

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }
}