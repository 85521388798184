@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.featured {
  $root: &;

  padding: rem(87) 0 rem(83);
  color: var(--color-white);

  &__inner {
    display: grid;
    grid-template-columns: 585px 1fr;
    gap: 30px;

    &:not(:last-child) {
      margin-bottom: rem(153);
    }

    h3 {
      margin-bottom: 20px;
      font-weight: var(--fw-600);
      font-size: 48px;
      line-height: 1.2;
    }


    &--reverse {
      grid-template-columns: 1fr 585px;

      .featured__content {
        grid-column: 2 / 3;
      }

      .featured__picture {
        justify-self: start;
        grid-area: 1 / 1 /2 / 2;
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 800px;
  }

  &__picture {
    justify-self: end;

    img {
      overflow: hidden;
      border-radius: var(--radius-main);
    }
  }

  h2 {
    margin-bottom: rem(49);
    color: var(--color-white);
  }

  h3 {
    color: var(--color-white);
  }

  h4 {
    margin-bottom: 3px;
    font-size: 32px;
    letter-spacing: 0.055em;
  }

  p {
    color: var(--color-white);

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &--help {
    color: var(--color-blue-400);
    background-color: var(--color-white);

    #{$root}__inner {
      align-items: center;

      &:not(:last-child) {
        margin-bottom: rem(104);

      }
    }

    h2,
    h3,
    p {
      color: inherit;
    }

    h2 {
      margin-bottom: 24px;
    }

    h3 {
      margin-bottom: 13px;
      font-size: 40px;
    }
  }

  @include media(tablet) {
    &__inner {
      grid-template-columns: 1fr;
      justify-content: center;

      img {
        aspect-ratio: 16 / 7;
        width: 100%;
      }
    }

    &__picture {
      max-width: 100%;
      width: 100%;
    }

    &__inner--reverse &__content {
      grid-column: auto;
      grid-row: 1 / 2;
    }

    &__inner--reverse &__picture {
      grid-column: auto;
      grid-row: 2 / 3;
    }
  }

  @include media(tablet) {
    #{$root}__inner {

      &:not(:last-child) {
        margin-bottom: rem(40);

      }
    }
  }
}