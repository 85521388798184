@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.case-desc {
  .grid {
    &:not(:last-child) {
      margin-bottom: rem(37);
    }

    &__item {
      padding-right: 40px;
    }

    p {
      margin-bottom: 0;
      color: var(--color-blue-400);
    }
  }

  h3 {
    margin-bottom: 10px;
    line-height: 1.2;
  }

  p {

    &:last-child {
      margin-bottom: 0;
    }
  }
}