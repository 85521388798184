@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: rem(82) 0 rem(84);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  &__wrapper {
    border-radius: var(--radius-main);
    padding: rem(83);
    width: 100%;
    max-width: 700px;
    background-color: var(--color-white);
  }

  &__form {
    width: 100%;
  }

  h2 {
    margin-bottom: 30px;
  }

  label {
    display: block;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 22px;
    }
  }

  input,
  textarea {
    border-color: transparent;
    border-radius: var(--radius-main);
    padding: 13px;
    width: 100%;
    color: var(--color-blue-400);
    background-color: #f7f7f7;
  }

  textarea {
    min-height: 240px;
    resize: none;
  }

  .btn {
    max-width: none;
    padding-block: 15px;
    padding-inline: 15px !important;
  }

  @include media(mobile-l) {
    &__wrapper {
      padding: 40px;
    }

    .btn {
      width: 100%;
    }
  }

  @include media(mobile-m) {
    h2 {
      margin: 0 auto 40px;
      text-align: center;
    }

    &__wrapper {
      padding: 40px 20px;
    }

    &__inner {
      flex-direction: column;
      align-items: center;
    }

    &__form {
      margin-right: 0;
      margin-bottom: 40px;
      max-width: none;
    }
  }
}