/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

:root {
  --content-width: 1180px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "SFProDisplay", sans-serif;
  --font-family-secondary: "Open Sans Hebrew", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #fff;
  --color-black: #000;

  --color-dark-400: #444444;
  --color-dark-450: #3b3b3b;
  --color-dark-900: #292e3d;
  --color-dark-800: #1a2429;

  --color-blue-100: #b9c7d4;
  --color-blue-400: #354d64;

  //filter

  //  radius
  --radius-main: 12px;

  // shadow

  // gradient
  --gradient: linear-gradient(89.88deg, rgba(0, 0, 0, 0.528) 59.48%, rgba(0, 0, 0, 0.304) 78.99%, rgba(0, 0, 0, 0) 99.89%);

  // icons
  --arrow: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.07745 0.744078C1.40289 0.418641 1.93053 0.418641 2.25596 0.744078L8.92263 7.41074C9.24807 7.73618 9.24807 8.26382 8.92263 8.58926L2.25596 15.2559C1.93053 15.5814 1.40289 15.5814 1.07745 15.2559C0.752015 14.9305 0.752015 14.4028 1.07745 14.0774L7.15486 8L1.07745 1.92259C0.752015 1.59715 0.752015 1.06951 1.07745 0.744078Z' fill='%23000'/%3E%3C/svg%3E%0A");

  --user: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00001 17.3335C7.74006 17.337 6.49603 17.0519 5.36335 16.5001C4.9488 16.2985 4.55162 16.0629 4.17585 15.7959L4.06168 15.7126C3.02819 14.9498 2.18324 13.9603 1.59168 12.8201C0.979792 11.64 0.662435 10.3294 0.666637 9.00008C0.666637 4.39771 4.39764 0.666748 9.00001 0.666748C13.6024 0.666748 17.3334 4.39771 17.3334 9.00008C17.3375 10.3287 17.0205 11.6387 16.4092 12.8184C15.8184 13.9579 14.9747 14.9471 13.9425 15.7101C13.5531 15.9951 13.14 16.246 12.7075 16.4601L12.6408 16.4934C11.5074 17.0482 10.2619 17.3355 9.00001 17.3335ZM9.00001 13.1667C7.75124 13.1643 6.6064 13.8618 6.03584 14.9726C7.90367 15.8977 10.0963 15.8977 11.9642 14.9726V14.9684C11.3929 13.8588 10.248 13.1629 9.00001 13.1667ZM9.00001 11.5001C10.8051 11.5024 12.4695 12.4752 13.3575 14.0468L13.37 14.0359L13.3817 14.0259L13.3675 14.0384L13.3592 14.0451C15.4667 12.2243 16.2203 9.28536 15.2489 6.67517C14.2775 4.06498 11.7859 2.33368 9.00085 2.33368C6.21577 2.33368 3.72417 4.06498 2.7528 6.67517C1.78144 9.28536 2.53501 12.2243 4.64251 14.0451C5.53106 12.4743 7.19531 11.5023 9.00001 11.5001ZM9.00001 10.6667C7.15906 10.6667 5.66668 9.17437 5.66668 7.33342C5.66668 5.49247 7.15906 4.00008 9.00001 4.00008C10.841 4.00008 12.3333 5.49247 12.3333 7.33342C12.3333 8.21747 11.9822 9.06532 11.357 9.69044C10.7319 10.3156 9.88407 10.6667 9.00001 10.6667ZM9.00001 5.66675C8.07954 5.66675 7.33335 6.41294 7.33335 7.33342C7.33335 8.25389 8.07954 9.00008 9.00001 9.00008C9.92049 9.00008 10.6667 8.25389 10.6667 7.33342C10.6667 6.41294 9.92049 5.66675 9.00001 5.66675Z' fill='%23000'/%3E%3C/svg%3E%0A");
}