@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.work {
  padding: rem(84) 0;
  background-color: var(--color-white);

  h2 {
    margin-bottom: 14px;

    +p {
      margin-bottom: rem(34);
      max-width: 700px;
    }
  }

  .grid {
    &__item {
      color: var(--color-white);
      background-color: var(--color-blue-400);
    }

    h3 {
      color: inherit;
    }
  }
}