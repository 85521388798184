@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.values {
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &__inner {
    padding: rem(68) 0;
  }

  .grid {
    margin-bottom: rem(34);

    h3 {
      margin-bottom: 10px;
    }
  }

  .btn {
    margin: 0 auto;
    max-width: 160px;
  }
}