@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding: rem(60) 0;
  color: var(--color-white);
  background-color: var(--color-blue-400);

  .logo {
    display: block;
    margin: 0 auto 24px;
    max-width: 126px;

    img {
      margin: 0 auto;
      width: fit-content;
      filter: invert(1);
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  .nav {
    margin-bottom: 47px;

    &__inner {
      justify-content: center;
    }

    &__list {
      margin: 0;

      @include media(tablet) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
      }
    }

    &__item {
      &:not(:last-child) {
        margin-right: rem(50);

        @include media(tablet-wide) {
          margin-right: 20px;
        }
      }
    }

    &__link {
      color: var(--color-white);
    }
  }

  .copyright {
    text-align: center;
    font-size: 16px;
  }

  button.formsapp-popup-button,
  .btn {
    padding: 10px 16px !important;
  }
}