@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  overflow: hidden;
  width: fit-content;
  font-weight: var(--fw-700);
  line-height: 1.05;
  color: var(--color-dark-400);
}

h1 {
  font-size: clamp(2.25rem, 1.9951rem + 1.0458vi, 56px);

  @include media(mobile-l) {
    font-size: 34px;
  }
}

h2,
.title {
  margin-bottom: rem(32);
  font-size: clamp(2rem, 1.7143rem + 1.4286vi, 3rem);
  letter-spacing: -0.015em;
  text-align: left;
  color: inherit;
}

h3,
.title--sec {
  font-size: 32px;
}

p {
  font-size: 20px;
  line-height: 1.4;
}