@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.service {
  padding: rem(67) 0;

  h2 {
    margin-bottom: 15px;

    +p {
      margin-bottom: rem(34);
      max-width: 650px;
      font-size: 20px;
      line-height: 1.4;
    }
  }

  .btn {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 160px;
    padding-inline: 22px;
  }

  &--home {
    .grid {
      margin-bottom: rem(32);
      gap: 20px;

      &__item {
        &::before {
          content: "";
          position: relative;
          display: block;
          margin-bottom: 22px;
          width: 86px;
          height: 86px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        &:first-child {
          padding-top: rem(62);
          padding-bottom: rem(62);

          &::before {
            margin-bottom: 31px;
          }

          h3 {
            margin-bottom: 15px;
            font-size: 40px;
          }

          p {
            line-height: 1.4;
          }
        }

        &--collaboration {
          &::before {
            background-image: url("../images/icons/collaboration.svg");
          }
        }

        &--analyze {
          &::before {
            background-image: url("../images/icons/analyze.svg");
          }
        }

        &--statistics {
          &::before {
            background-image: url("../images/icons/statistics.svg");
          }
        }

        &--doughnut {
          &::before {
            background-image: url("../images/icons/doughnut.svg");
          }
        }

        &--speed {
          &::before {
            background-image: url("../images/icons/speed.svg");
          }
        }

        &--error {
          &::before {
            background-image: url("../images/icons/error.svg");
          }
        }
      }
    }
  }

  &--services {
    padding: 20px 0;
    color: var(--color-white);

    &:not(:last-child) {
      padding-bottom: 50px;
    }

    .grid {
      &__item {
        padding-right: 28px;
      }

      h3 {
        line-height: 1.15;
      }

      p {
        color: var(--color-dark-400);
      }
    }

    h2 {
      +p {
        margin-bottom: rem(70);
        max-width: 680px;
      }

      &:not(:first-child) {
        margin-bottom: 24px;
        font-size: 40px;
        letter-spacing: 0;

        +p {
          margin-bottom: rem(32);
        }
      }
    }
  }

  &--analitycs {
    padding-top: 37px;

    h2 {
      font-size: 40px;
      letter-spacing: 0;

      +p {
        margin-bottom: rem(37);
      }
    }
  }
}