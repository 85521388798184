@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.article {
  padding: rem(68) 0 rem(83);
  color: var(--color-white);

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  &__content {
    overflow: hidden;
    border-radius: var(--radius-main);
    padding: 45px 40px 34px;
  }

  h1 {
    margin-bottom: 40px;
    line-height: 1.2;
    text-transform: none;
  }

  h2 {
    margin-bottom: 22px;
    font-family: var(--font-family-primary);
    font-size: 30px;
    letter-spacing: 0.035em;
  }

  img {
    overflow: hidden;
    margin-bottom: 44px;
    border-radius: var(--radius-main);
    aspect-ratio: 16 / 6;
  }

  p {
    margin-bottom: 44px;
    max-width: 680px;
    font-family: var(--font-family-primary);
  }

  @include media(tablet) {
    padding: 30px 0;

    &__content {
      padding: 40px 20px;
    }
  }
}

.articles {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  @include media(mobile-l) {
    &__grid {
      gap: 15px;
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-auto-rows: 300px;
      grid-template-columns: 1fr;
    }
  }
}